<template>
  <div>
    Password Strength: {{strengthMessage}}
    <v-progress-linear
        :color="strengthColor"
        v-model="strengthNumber"></v-progress-linear>
    <v-list>
      <v-list-item class="checklist-item">
        <v-list-item-title>
          <span :style="{color: hasMinLength ? 'green' : 'red'}" class="checklist-text">
              <v-icon class="checklist-icon"
                  :color="hasMinLength ? 'green' : 'red'"
                  small>{{hasMinLength ? 'fa fa-check' : 'fa fa-times'}}</v-icon>
              At least <strong>8 Characters</strong>
            </span>
        </v-list-item-title>
      </v-list-item>
      <v-list-item class="checklist-item">
        <v-list-item-title>
          <span :style="{color: hasLowercaseLetter ? 'green' : 'red'}" class="checklist-text">
              <v-icon
                  :color="hasLowercaseLetter ? 'green' : 'red'"
                  class="checklist-icon"
                  small>{{hasLowercaseLetter ? 'fa fa-check' : 'fa fa-times'}}</v-icon>
              At least <strong>one Lowercase Letter</strong>
            </span>
        </v-list-item-title>
      </v-list-item>
      <v-list-item class="checklist-item">
        <v-list-item-title>
          <span :style="{color: hasUppercaseLetter ? 'green' : 'red'}" class="checklist-text">
              <v-icon
                  :color="hasUppercaseLetter ? 'green' : 'red'"
                  class="checklist-icon"
                  small>{{hasUppercaseLetter ? 'fa fa-check' : 'fa fa-times'}}</v-icon>
              At least <strong>one Uppercase Letter</strong>
            </span>
        </v-list-item-title>
      </v-list-item>
      <v-list-item class="checklist-item">
        <v-list-item-title>
          <span :style="{color: hasNumber ? 'green' : 'red'}" class="checklist-text">
              <v-icon
                  :color="hasNumber ? 'green' : 'red'"
                  class="checklist-icon"
                  small>{{hasNumber ? 'fa fa-check' : 'fa fa-times'}}</v-icon>
              At least <strong>one Number</strong>
            </span>
        </v-list-item-title>
      </v-list-item>
      <v-list-item two-line class="checklist-item">
        <v-list-item-content style="padding-top: 2px">
          <v-list-item-title>
            <span :style="{color: hasSpecialCharacter ? 'green' : 'red'}" class="checklist-text">
              <v-icon
                  :color="hasSpecialCharacter ? 'green' : 'red'"
                  class="checklist-icon"
                  small>{{hasSpecialCharacter ? 'fa fa-check' : 'fa fa-times'}}</v-icon>
              At least <strong>one Special Character</strong>
            </span>
          </v-list-item-title>
          <v-list-item-subtitle style="padding-left: 40px">
            <span style="font-style: italic">Valid characters include: </span><br> <code>^$*.[]{}()?-"!@#%&/\,><':;|_~`+=</code>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "v-pass-validator",
  props: ['value'],
  computed: {
    hasMinLength() {
      return this.value && /[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}/.test(this.value);
    },
    hasNumber() {
      return this.value && /(?=.*[0-9])/.test(this.value);
    },
    hasSpecialCharacter() {
      const specialCharacters = /(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/;
      return this.value && specialCharacters.test(this.value);
    },
    hasUppercaseLetter() {
      return this.value && /(?=.*[A-Z])/.test(this.value);
    },
    hasLowercaseLetter() {
      return this.value && /(?=.*[a-z])/.test(this.value);
    },
    strengthNumber() {
      let score = 0;
      //8 length min
      if (this.hasMinLength) { score++; }
      //1 number
      if (this.hasNumber) { score++; }
      //1 special character
      if (this.hasSpecialCharacter) { score++; }
      //1 uppercase letter
      if (this.hasUppercaseLetter) { score++; }
      //1 lowercase letter
      if (this.hasLowercaseLetter) { score++; }

      return this.value ? (score * 20) : 0;
    },
    strengthColor() {
      let color = "red";
      if (this.strengthNumber === 100) {
        color = "green";
      } else if (this.strengthNumber > 70) {
        color = "yellow";
      } else if (this.strengthNumber > 30) {
        color = "orange";
      }
      return color;
    },
    strengthMessage() {
      let color = "Very Weak";
      if (this.strengthNumber === 100) {
        color = "Strong";
      } else if (this.strengthNumber > 70) {
        color = "Medium";
      } else if (this.strengthNumber > 30) {
        color = "Weak";
      }
      return color;
    }
  }
}
</script>

<style scoped>
.checklist-icon {
  margin-top: -3px;
  width: 30px;
  margin-right: 4px;
}

.checklist-item {
  min-height: 25px;
  padding-left: 10px;
}

.checklist-text {
  font-size: .9em;
}
</style>