<template>
  <v-container class="fill-height stretch">
    <sign-in-panel></sign-in-panel>
  </v-container>
</template>

<script>
import {VueController} from "../service/support/vuex-controller";
import SignInPanel from "../components/SignInPanel";
import Auth from "@aws-amplify/auth";

export default VueController('signIn', {
  components: {
    SignInPanel
  },
  state: [
    "cognito",
    "pageIsLoading"
  ],
  beforeMount() {
    if (this.cognito) {
      Auth.currentAuthenticatedUser()
          .then(user => {
            this.$router.push('/vaults');
          })
          .catch((error) => {
            console.error(error);
          });
    }
  }
});
</script>

<style scoped>

</style>
