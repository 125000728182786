import {VueController} from "@/service/support/vuex-controller";
import {EventBus} from '@/service/support/event-bus';
import  baseService  from '../service/support/base-service';
import orgService from '../service/org-service';
import uuid from 'uuid';
import Auth from '@aws-amplify/auth';
import * as awsVars from '../config/aws-exports';
import VPassValidator from "../components/VPassValidator"
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'

let awsmobile = awsVars.default(process.env);

export default VueController("SignInPanel", {
    state: [
        "subscriptionPlanOptions"
    ],
    data() {
        return {
            showPassword: false,
            step: 0,
            magicCodeInput: "",
            email: "",
            name: "",
            orgName: "",
            password: "",
            newPass: "",
            snackBar: false,
            snackBarError: "",
            pwChange: false,
            user: {},
            confirmCode: "",
            verify: false,
            verifyModal: false,
            pwReset: false,
            pwCheck: "",
            pwConfirmed: false,
            loginInProgress: false,
            loginReason: "",
            acceptVaultId: null,
            registerClicked: false,
            selectedPlan: "professional",
            selectedBillingPeriod: "monthly",
            loginOrgId: "",
            logoBucket: null
        }
    },
    props: {
        source: {
            type: String,
            default: ''
        },
        bgColor: {
            type: String,
            default: '#074024'
        },
        fgColor: {
            type: String,
            default: 'white'
        }
    },
    computed: {
        queryStep() {
            return this.step;
        },
        showHideLabel() {
            return this.showPassword ? "Hide" : "Show";
        }
    },
    created() {
        this.logoBucket = awsmobile.aws_org_logos_s3_bucket;
    },
    mounted() {
        this.loginReason = this.$route.query.reason;

        let orgId = this.$route.query.org ? this.$route.query.org : null;
        if (this.$route.query.redirect && !orgId) {
            let pathParts = this.$route.query.redirect.split("/");

            for (let i = 0; i < pathParts.length; i++) {
                if (pathParts[i] === "org" && pathParts.length > i) {
                    orgId = pathParts[i + 1];
                    break;
                }
            }
        }

        this.logoBucket = awsmobile.aws_org_logos_s3_bucket;

        if (orgId) {
            if (orgId !== 'zapa') {
                this.loginOrgId = orgId;
                this.setCookie("org", this.loginOrgId, 30);
            } else {
                this.loginOrgId = "";
                this.setCookie("org", "", 30);
            }
        } else {
            orgId = this.getCookie("org");
            if (orgId) {
                this.loginOrgId = orgId;
            }
        }

        if (this.$route.query.plan) {
            this.selectedPlan = this.$route.query.plan;
            this.setCookie("plan", this.selectedPlan, 30);

            if (this.$route.query.billing) {
                this.selectedBillingPeriod = this.$route.query.billing ? this.$route.query.billing : "monthly";
                this.setCookie("billing", this.selectedBillingPeriod, 30);
            }

        } else {
            let planCookie = this.getCookie("plan");
            if (planCookie) {
                this.selectedPlan = planCookie;
            }

            let billingCookie = this.getCookie("billing");
            if (billingCookie) {
                this.selectedBillingPeriod = billingCookie;
            }
        }

        //AUTO REDIRECTION FLOWS:
        //activate = code for SignUp flow
        //verify = code for ResendCode flow
        //forgot = code to Reset Forgotten Password flow
        //tmp = New User First Time Login

        //PANEL STEPPER FLOWS:
        //redirect = login then get back to url
        //step = 1 is Login
        //step = 2 is Register
        //step = 3 is Forgot Password

        if (this.$route.query.email) {
            this.email = this.$route.query.email.toLowerCase();
        }
        if (this.$route.query.name){
            this.name = this.$route.query.name;
        }
        if (this.$route.query.accept) {
            this.acceptVaultId = this.$route.query.accept;
        }

        if (this.$route.query.activate) {
            this.confirmCode = this.$route.query.activate;
            this.confirmRegistration();
        } else if (this.$route.query.verify) {
            this.confirmCode = this.$route.query.verify;
            this.confirmRegistration();
        } else if (this.$route.query.magic) {
            this.step = 6;
            this.verifyMagic(this.$route.query.magic);
        } else if (this.$route.query.forgot) {
            this.confirmCode = this.$route.query.forgot;
            this.pwReset = true;
            this.step = 3;
        } else if (this.$route.query.tmp) {
            //capture tmp pass
            this.password = this.$route.query.tmp;
            //call login which will trigger change pass
            this.logInNew();
        } else if (this.$route.query.step) {
            //Step 2 is register window
            this.step = parseInt(this.$route.query.step);
        } else {
            this.step = 1;
        }
        this.snackBarError = "";
    },
    methods: {
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        priceDescription(planName) {
            let isMonthly = this.selectedBillingPeriod === "monthly";
            let info = this.planInfo(planName);
            let cost = info.price[isMonthly ? "monthly" : "yearly"];
            if (!isMonthly) {
                cost = (parseInt(cost.replace("$", "")) * 12).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                });
            } else {
                cost = cost + ".00";
            }
            return cost + " per " + (this.selectedBillingPeriod === "monthly" ? "month" : "year");
        },
        checkEmailCaps(email) {
            let emailArray = JSON.parse(atob("WwogICAgICAgICAgICAiTGVub21kZXBsdW1lQHlhaG9vLmNvbSIsCiAgICAgICAgICAgICJKdW5lYnVnamFuZXRAZ21haWwuY29tIiwKICAgICAgICAgICAgIk15Ym95bm9haEBjb21jYXN0Lm5ldCIsCiAgICAgICAgICAgICJDaGF0bGFkeTE5NDBAZ21haWwuY29tIiwKICAgICAgICAgICAgIkxjYjAwOTdASG90bWFpbC5jb20iLAogICAgICAgICAgICAiaW5mb0BTQVNyZW1vdGUuY29tIiwKICAgICAgICAgICAgIlJhbWlyZXpqb2hueUBnbWFpbC5jb20iLAogICAgICAgICAgICAiSkdvcnNreUB3dHBsYXcuY29tIiwKICAgICAgICAgICAgIkJsYXJyYWJlZTY2QGljbG91ZC5jb20iLAogICAgICAgICAgICAiSk1haGVuZHJhbkBhcHBsaWVkY29tcG9zaXRlcy5jb20iLAogICAgICAgICAgICAiSmFja3lUaXJhZG9Ab3V0bG9vay5jb20iLAogICAgICAgICAgICAiTUsxNjIzQGFvbC5jb20iLAogICAgICAgICAgICAiRndlYmJAd2ViYmVudmlyb25tZW50YWwuY29tIiwKICAgICAgICAgICAgIk15R3JvdW5kc0tlZXBlckB0ZWx1cy5uZXQiLAogICAgICAgICAgICAiS2VsbGllLmZlY2tAaG90bWFpbC5jb20iXQ=="));
            for (let e = 0; e < emailArray.length; e++) {
                if (email.toLowerCase() === emailArray[e].toLowerCase()) {
                    //If email matches a user above use the capitalized email
                    return emailArray[e];
                }
            }
            //If no match is found lower case it.
            return email.toLowerCase().trim();

        },
        thirtyDaysOut() {
            let future = new Date();
            future.setDate(future.getDate() + 30);
            const options = {year: "numeric", month: "long", day: "numeric"}
            return new Date(future).toLocaleDateString(undefined, options);
        },
        planInfo(plan) {
            let planInfo = this.subscriptionPlanOptions.find(planDetails => planDetails.id === plan);
            return planInfo;
        },
        setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
        getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        forgotPassword(event) {

            if (event) {
                try {
                    event.preventDefault = true;
                    event.cancelBubble = true;
                } catch (error) {
                    //console.log("Unable to stop form.");
                }
            }

            let self = this;
            self.password = "";

            self.snackBarError = "";
            self.loginInProgress = true;
            self.email = self.checkEmailCaps(self.email);
            Auth.forgotPassword(self.email).then(response => {
                console.log("Password Reset request from : " + self.email + " sent successfully.");
                self.pwReset = true;
                self.loginInProgress = false;
            }).catch(err => {
                self.loginInProgress = false;

                console.error(err);
                //Let's attempt to resent the verification code.
                Auth.resendSignUp(self.email).then(resendResponse => {
                    console.log(resendResponse); //open confirm code flow
                    self.snackBarError = "Password reset link has been emailed to " + self.email + ", please check your email and click on the verification link.";
                }).catch(errResend => {
                    console.log(errResend);
                    if (errResend.message === "User is already confirmed.") {
                        self.snackBarError = "Unable to reset password for " + self.email + ", if you were invited to a portal please request that your invitation be resent.";
                    } else if (errResend.code === "NotAuthorizedException" && errResend.message.indexOf("Can't resend confirmation code") > -1) {
                        self.snackBarError = "Unable to reset password for " + self.email + ", if you were invited to a portal please request that your invitation be resent.";
                    } else if (errResend.code === "LimitExceededException") {
                        self.snackBarError = "Too many password reset attempts for " + self.email + ", please wait some time before trying again.";
                    } else {
                        self.snackBarError = "Unable to reset password for " + self.email + ", please try again or contact support for assistance.";
                    }
                });
            });
        },
        forgotPasswordSubmit() {
            let self = this;
            self.snackBarError = "";
            self.loginInProgress = true;
            if (!self.pwCheck || !self.newPass) {
                self.loginInProgress = false;
                self.snackBarError = "Password cannot be empty. Please enter a new password to continue.";
            } else if (self.newPass === self.pwCheck) {
                self.email = self.checkEmailCaps(self.email);
                Auth.forgotPasswordSubmit(self.email, self.confirmCode, self.newPass).then(response => {
                    try {
                        woopra.identify({email: self.email}).track("Forgot Password", {email: self.email});
                    } catch (woopraError) {
                        console.error("Woopra not loaded.");
                    }
                    self.password = self.newPass;
                    self.logInNew();
                }).catch(err => {
                    self.loginInProgress = false;
                    if (err.code === "ExpiredCodeException") {
                        self.step = 3;
                        self.snackBarError = "This reset password token has expired. Please request a new email to reset your password.";
                        console.error(err);
                    } else {
                        if (err.message) {
                            //self.notification("error", err.message);
                            self.snackBarError = err.message;
                        } else {
                            self.snackBarError = "Unable to update password for " + self.email + ", please try again or contact support for assistance.";
                        }

                        console.error(err);
                    }
                })
            } else {
                self.loginInProgress = false;
                self.snackBarError = "Passwords do not match.";
                self.pwCheck = "";
                self.newPass = "";
            }
        },
        tempPasswordChange(user) {
            let self = this;
            self.loginInProgress = true;
            self.snackBarError = "";

            if (!self.pwCheck || !self.newPass) {
                self.snackBarError = "Password cannot be empty. Please enter a new password to continue.";
                self.loginInProgress = false;
            } else if (self.pwCheck === self.newPass) {
                Auth.completeNewPassword(user, self.newPass, {}).then(user => {
                    // password updated and logged in
                    self.loginInProgress = false;
                    self.pwChange = false;
                    console.log("New password accepted, logged in");
                    orgService.mutation("setUserToEmailVerified").then(newUserId => {
                        EventBus.$emit('authState', {
                            status: 'signedIn',
                            redirect: self.$route.query.redirect && self.$route.query.redirect.indexOf('/signin') > -1 ? undefined : self.$route.query.redirect,
                            acceptVaultId: self.acceptVaultId
                        });
                    });
                }).catch(err => {
                    self.loginInProgress = false;
                    if (err.code === "InvalidPasswordException" && err.message) {
                        self.snackBarError = err.message;
                    } else {
                        self.snackBarError = "Unable to set password for " + this.email + ", please try again or contact support for assistance.";
                    }
                    console.error("Unable to set password: " + JSON.stringify(err));
                });
            } else {
                self.loginInProgress = false;
                self.snackBarError = "Passwords do not match.";
                self.pwCheck = "";
                self.newPass = "";
            }
        },
        registerUser() {
            let self = this;
            self.loginInProgress = true;
            self.snackBarError = "";
            self.email = self.checkEmailCaps(self.email);
            this.registerClicked = true;
            if (self.orgName && self.name && self.email && self.password.length > 7) {
                Auth.signUp({
                    username: self.email,
                    password: self.password,
                    attributes: {
                        name: self.name,
                        email: self.email,
                        'custom:orgName': self.orgName,
                        'custom:mutableOrgId': uuid(),
                        'custom:mutableUserType': 'admin'
                    }
                }).then(data => {
                    try {
                        woopra.identify({
                            name: self.name,
                            email: self.email,
                            Company: self.orgName,
                            userType: 'admin'
                        }).track("Sign Up", {
                            tier: self.selectedPlan ? self.selectedPlan : "professional",
                            discount: self.selectedBillingPeriod === "annual"
                        });
                    } catch (woopraError) {
                        console.error("Woopra not loaded.");
                    }
                    self.loginInProgress = false;
                    console.log("User signed up successfully, email unconfirmed");
                    self.verifyModal = true;
                }).catch(err => {
                    //TODO Specific error if user already exists ?
                    if (err.code === "InvalidParameterException") {
                        self.snackBarError = err.message;
                    } else if (err.code === "InvalidPasswordException") {
                        self.snackBarError = err.message;
                    } else {
                        self.snackBarError = "Unable to create user, please try again.";
                        console.log("Error creating User:" + JSON.stringify(err));
                    }

                    self.loginInProgress = false;
                })
            } else {
                self.loginInProgress = false;
                self.snackBarError = "Please complete all required fields.";
            }
        },
        confirmRegistration() {
            let self = this;
            self.email = self.checkEmailCaps(self.email);
            self.loginInProgress = true;
            self.snackBarError = "";
            Auth.confirmSignUp(self.email, self.confirmCode).then(response => {
                self.verified = true;
                woopra.track("Confirm Registration");
                if (self.password) {
                    self.logInNew(true);
                } else {
                    self.loginInProgress = false;
                    self.step = 4;
                }
            }).catch(err => {
                console.error("Error confirming User Signup:" + err);
                self.loginInProgress = false;

                if (err.message) {
                    self.snackBarError = "Unable to confirm user registration, please contact support for assistance.";
                    console.error(err.message);
                } else {
                    self.snackBarError = "Unable to confirm user registration, please contact support for assistance.";
                    console.error(err);
                }
            });
        },
        loginMagic() {
            let self = this;
            self.snackBarError = "";
            self.loginInProgress = true;

            if (!self.email) {
                self.snackBarError = "Please enter an email address.";
                self.loginInProgress = false;
            } else {
                try {
                    self.email = self.email.toLowerCase();
                } catch (e) {
                    console.log("Unable to set email as lowercase.");
                }
                Auth.configure({authenticationFlowType: "CUSTOM_AUTH"});
                Auth.signIn(self.email).then(user => {
                    localStorage.setItem(
                        'magicUser',
                        JSON.stringify({
                            username: self.email,
                            session: user.Session,
                        })
                    );
                    self.step = 6;
                    self.loginInProgress = false;
                }).catch(error => {
                    //console.log(JSON.stringify(error));
                    self.step = 6;
                    self.loginInProgress = false;
                });
            }
        },
        verifyMagic(magicCode) {
            console.log("Initial param: " + magicCode);
            let self = this;
            self.snackBarError = "";
            self.loginInProgress = true;

            if (!magicCode) {
                magicCode = self.magicCodeInput;
            }

            //console.log("Verify: " + magicCode);
            let magicUser = null;
            if (localStorage["magicUser"]) {
                magicUser = JSON.parse(localStorage.getItem("magicUser"));
            }

            if (!magicCode) {
                self.snackBarError = "Please enter a verification code send with the magic login link.";
                self.loginInProgress = false;
            } else if (magicUser && magicUser.username === self.email) {
                //console.log(JSON.stringify(magicUser));
                console.log("Shouldnt get here.");

                const authenticationData = {
                    Username: magicUser.username
                };
                const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
                    authenticationData
                );
                const poolData = {
                    UserPoolId: awsmobile.aws_user_pools_id,
                    ClientId: awsmobile.aws_user_pools_web_client_id
                };

                const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
                const userData = {
                    Username: magicUser.username,
                    Pool: userPool
                };
                const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
                // Grab the prior session from when the link was requestsed
                cognitoUser.Session = magicUser.session
                // user rehydration does not trigger a new email
                cognitoUser.setAuthenticationFlowType('CUSTOM_CHALLENGE');

                Auth.sendCustomChallengeAnswer(cognitoUser, magicCode).then(signedUser => {
                    console.log("User signed successfully.");

                    try {
                        Auth.currentSession().then(response => {
                            console.log("Magic session verified.");
                            self.loginInProgress = false;
                            localStorage.removeItem("magicUser");
                            self.$router.push("/vaults");
                            //console.log(JSON.stringify(response));
                        }).catch(authErr => {
                            self.snackBarError = "Unable to verify user session, please request a new link and try again.";
                            self.step = 5;
                            //console.log(JSON.stringify(authErr));
                            self.loginInProgress = false;
                        });
                    } catch (err) {
                        self.snackBarError = "For security reasons, we are unable to verify this session. Please request a new link and try again.";
                        self.step = 5;
                        //console.log(JSON.stringify(err));
                        self.loginInProgress = false;
                    }
                }).catch(error => {
                    console.log(JSON.stringify(error));

                    self.snackBarError = "The magic login code is not valid. If more than 3 minutes have elapsed since this link was requested, please request a new link.";
                    //self.step = 5;
                    self.loginInProgress = false;
                });
            } else {
                console.log("Not the same browser.");
                setTimeout(() => {
                    self.snackBarError = "Please use the same browser that you requested the magic link from. The link expires in only 3 minutes.";
                }, 0);

                //self.step = 5;
                self.loginInProgress = false;
            }
        },
        logInNew(newUserConfirmation) {
            let self = this;

            //console.log("login fired.");
            self.snackBarError = "";

            if (!self.email) {
                self.snackBarError = "Please enter an email address.";
                return;
            }

            if (!self.password) {
                self.snackBarError = "Please enter a password.";
                return;
            }

            self.loginInProgress = true;

            self.email = self.checkEmailCaps(self.email);
            Auth.signIn(self.email, self.password).then(user => {
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    self.user = user;
                    self.pwChange = true;
                    self.loginInProgress = false;
                } else {
                    //logged in no password change required
                    try {
                        woopra.identify({
                            email: self.email,
                            name: user.attributes.name,
                            userType: user.attributes['custom:mutableUserType']
                        }).track("Log In");
                    } catch (woopraError) {
                        console.error("Woopra not loaded.");
                    }
                    if (self.loginInProgress === true) {
                        Auth.currentAuthenticatedUser({
                            bypassCache: true
                        }).then(() => {

                            if (newUserConfirmation === true) {
                                self.$router.push("/new?plan=" + (self.selectedPlan ? self.selectedPlan : "professional") + "&discount=" + (self.selectedBillingPeriod === "annual" ? "true" : "false"));
                            } else {
                                EventBus.$emit('authState', {
                                    status: 'signedIn',
                                    redirect: self.$route.query.redirect && self.$route.query.redirect.indexOf('/signin') > -1 ? undefined : self.$route.query.redirect,
                                    plan: self.selectedPlan,
                                    discount: self.selectedBillingPeriod === "annual",
                                    acceptVaultId: self.acceptVaultId
                                });
                            }
                            //self.loginInProgress = false;
                        });
                    }
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message.indexOf("has expired") > -1) {
                        self.snackBarError = "Your invitation email token has expired, please ask your portal host to send a new invitation.";
                    } else if (err.code === "NotAuthorizedException") {
                        //Per OWASP Auth best practices: https://github.com/OWASP/CheatSheetSeries/blob/master/cheatsheets/Authentication_Cheat_Sheet.md#authentication-and-error-messages
                        //Cannot indicate if it was the username or password
                        //Cannot indicate if account exists
                        //Cannot indicate if account if locked, verified, or disabled
                        self.snackBarError = "Incorrect username or password. If you have been recently invited to a portal, please check your email for an activation link.";
                    } else {
                        self.snackBarError = err.message;
                    }
                } else {
                    self.snackBarError = err;
                }

                self.loginInProgress = false;
            });
        }
    },
    components: {
        VPassValidator
    }
});
